//
//
//
//
//
//

// @ is an alias to /src

export default {
  name: 'AssuranceBien'
}
